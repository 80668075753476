import React from 'react';
import CoreApplication, { TargetApplication } from '@esend/core';
import awsconfig from './aws-exports';

function App() {
  return (
    <CoreApplication target={TargetApplication.ADMIN} amplifyConfiguration={awsconfig} />
  );
}

export default App;
