// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile =  {
  'aws_project_region': 'eu-central-1',
  'aws_appsync_graphqlEndpoint': 'https://4wj77qtc55em5b3ls44k7gafju.appsync-api.eu-central-1.amazonaws.com/graphql',
  'aws_appsync_region': 'eu-central-1',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  'aws_appsync_apiKey': 'null',
  'aws_cognito_identity_pool_id':  'eu-central-1:f49093a0-0647-4393-92e5-d29ebc11c39b',
  'aws_cognito_region': 'eu-central-1',
  'aws_user_pools_id': 'eu-central-1_0eYiCywLC',
  'aws_user_pools_web_client_id': '66hp7rlb3cduapafe0o6nf74ql',
  'oauth': {},
  'region': 'eu-central-1',
  'identityPoolId': 'eu-central-1:f49093a0-0647-4393-92e5-d29ebc11c39b',  
  'identityPoolRegion': 'eu-central-1',
  'userPoolId': 'eu-central-1_0eYiCywLC',
  'userPoolWebClientId': '66hp7rlb3cduapafe0o6nf74ql',
  'aws_user_files_s3_bucket': 'esend-application-content',
  'aws_user_files_s3_bucket_region': 'eu-central-1',
  'esend_driver_pictures': 'esend-driver-pictures',
  'esend_invoicing': 'esend-invoicing',
  'esend_environment': 'demo',
  "build_env": "master"
};
export default awsmobile;